<template>
  <div>
    <div v-if="type == 3">
      <el-upload
        action=""
        :accept="accept"
        class="picture-uploader"
        list-type="picture-card"
        multiple
        :class="[size]"
        :auto-upload="false"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="imgChangeNUpload"
      >
        <div slot="default" class="default-upload-btn">
          <i class="el-icon-plus"></i>
          <span v-if="title">{{ title }}</span>
        </div>
        <div class="default-upload-file" slot="file" slot-scope="{ file }">
          <img
            class="el-upload-list__item-thumbnail"
            style="object-fit:cover;"
            :src="file.url"
            alt=""
          />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </div>
    <div v-if="type == 1">
      <el-upload
        action=""
        accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
        class="picture-uploader"
        list-type="picture-card"
        multiple
        :auto-upload="false"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="imgChangeNUpload"
      >
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        </div>
      </el-upload>
    </div>
    <div class="picture-uploader-type2" :class="[size]" v-if="type == 2">
      <el-upload
        action=""
        :accept="accept"
        class="uploader"
        multiple
        :auto-upload="false"
        :show-file-list="false"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="imgChangeNUpload"
      >
        <div v-if="fileList[0] && fileList[0].url">
          <img class="img" :src="fileList[0].url" />
          <div class="text center mask">
            <i class="el-icon-upload"></i>
            重新上传
          </div>
        </div>
        <div class="text center" v-else>
          <i class="el-icon-upload"></i>
          点击上传
        </div>
        <div class="loading" v-loading="loadingList[0]"></div>
      </el-upload>
      <p class="label" :class="{ 'f-span-required': required }" v-if="title">
        {{ title }}
      </p>
      <p class="tips" v-if="tips">{{ tips }}</p>
    </div>
    <div v-if="type == 4">
      <el-upload
        action=""
        accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
        class="uploader"
        multiple
        :auto-upload="false"
        :show-file-list="false"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="imgChangeNUpload"
      >
        <el-button type="primary" size="medium" icon="el-icon-document-add">
          {{ title }}
        </el-button>
        <div class="loading" v-loading="loadingList[0]"></div>
      </el-upload>
    </div>
    <div v-if="type == 5">
      <el-upload
        class="upload-demo"
        drag
        action=""
        multiple
        :accept="accept"
        :auto-upload="false"
        :file-list="fileList"
        :show-file-list="false"
        :on-change="imgChangeNUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="line-height:150%;">
          文档（支持word、Excel、PDF）、图片（支持JPG、png、gif），且单个文档不超过5M
        </div>
      </el-upload>
    </div>
    <div v-if="type == 6">
      <el-upload
        class="upload-demo"
        action=""
        :accept="accept"
        :auto-upload="false"
        :file-list="fileList"
        :show-file-list="false"
        :limit="limit"
        :on-change="imgChangeNUpload"
      >
        <el-button type="primary" size="small" plain>{{
          buttonText
        }}</el-button>
      </el-upload>
    </div>
    <div v-if="type == 7">
      <el-upload
        class="upload-demo"
        action=""
        multiple
        :accept="accept"
        :auto-upload="false"
        :file-list="fileList"
        :show-file-list="false"
        :on-change="imgChangeNUpload"
      >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-document-add"
          :loading="loadingList.some(item => item)"
        >
          {{ title }}
        </el-button>
      </el-upload>
    </div>
    <div class="uploader-type8" :class="[size]" v-if="type == 8">
      <el-upload
        action=""
        :accept="accept"
        multiple
        :auto-upload="false"
        :show-file-list="false"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="imgChangeNUpload"
      >
        <div class="file" v-if="fileList[0] && fileList[0].url">
          <div class="type-icon" @click.stop="">
            <img src="@/assets/word.png" v-if="fileList[0].docType == 'word'" />
            <img
              src="@/assets/excel.png"
              v-if="fileList[0].docType == 'excel'"
            />
            <img src="@/assets/pdf.png" v-if="fileList[0].docType == 'pdf'" />
            <img :src="item.url" v-if="fileList[0].docType == 'image'" />
          </div>
          <p class="text" @click.stop="">
            <span class="name">{{ fileList[0].name }}</span>
            <span class="size">{{ $format.byte(fileList[0].size) }}</span>
          </p>
          <p class="actions">
            <span class="el-icon-loading" v-if="loadingList[0]"></span>
            <template v-else>
              <span>[重新上传]</span>
              <span @click.stop="handleRemove(fileList[0])">[删除]</span>
            </template>
          </p>
        </div>
        <div class="uploader" v-else>
          <div class="text center">
            <i class="el-icon-upload"></i>
            选择文档
          </div>
          <div class="loading" v-loading="loadingList[0]"></div>
        </div>
      </el-upload>
    </div>
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog
      title="图片剪裁"
      :visible.sync="toggleCropperDialog"
      :close-on-click-modal="false"
      append-to-body
      width="540px"
    >
      <div class="upload-cropper">
        <vue-cropper
          ref="cropper"
          :img="cropperOption.img"
          :outputType="cropperOption.outputType"
          :autoCrop="cropperOption.autoCrop"
          :fixed="cropperOption.fixed"
          :fixedNumber="cropperOption.fixedNumber"
          :infoTrue="cropperOption.infoTrue"
          :centerBox="cropperOption.centerBox"
          :enlarge="cropperOption.enlarge"
        ></vue-cropper>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toggleCropperDialog = false">取 消</el-button>
        <el-button type="primary" @click="cropFinish">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="大图预览"
      :visible.sync="previewImageVisible"
      append-to-body
    >
      <img width="100%" :src="previewImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import mixin from "./mixin.js";
import { VueCropper } from "vue-cropper";
import { getRawType } from "@/utils";
export default {
  mixins: [mixin],
  components: {
    VueCropper
  },
  props: {
    buttonText: {
      type: String,
      default: "点击上传"
    },
    accept: {
      // 接受上传的文件类型
      type: String,
      default: "image/gif, image/jpeg, image/jpg, image/png, image/webp"
    },
    isCompress: {
      //是否压缩图片
      type: Boolean,
      default: true
    },
    imgW: {
      //裁切后图片输出宽度
      type: Number,
      default: 800
    },
    cropRatio: {
      //裁切框宽高比
      type: Array,
      default() {
        return [5, 4];
      }
    },
    isCrop: {
      //是否裁剪
      type: Boolean,
      default: false
    },
    limitSize: {
      // 图片大小 M
      type: Number,
      default: 10
    },
    type: {
      //上传组件类型
      type: [Number, String],
      default: 3
    },
    title: {
      //图片名字
      type: String,
      default: ""
    },
    tips: {
      //提示
      type: String,
      default: ""
    },
    required: {
      //是否必填
      type: Boolean,
      default: false
    },
    defaultFileList: {
      type: [Array, String, Object],
      default() {
        return [];
      }
    },
    showProcess: {
      // 是否需要获取上传进度
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  watch: {
    defaultFileList: {
      deep: true,
      immediate: true,
      handler(val) {
        val = this.$deepClone(val);
        if (!val) {
          this.fileList = [];
        } else if (getRawType(val) === "string") {
          this.fileList = [{ url: val }];
        } else if (val[0] && getRawType(val[0]) === "string") {
          this.fileList = val.filter(url => url).map(url => ({ url }));
        } else if (getRawType(val) === "object") {
          this.fileList = [val];
        } else {
          this.fileList = val;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.upload-cropper {
  .crop-info {
    display: none;
  }
}
</style>

<style scoped lang="scss">
.default-upload-btn {
  width: 100%;
  height: 100%;
  line-height: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  span {
    padding-top: 20px;
    color: #999;
  }
}
.default-upload-file {
  height: 100%;
}
.upload-cropper {
  width: 500px;
  height: 500px;
}
.picture-uploader {
  width: 100%;
  .el-upload-list__item,
  .el-upload--picture-card {
    width: 100px;
    min-width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .el-upload-list--picture-card {
    display: flex;
  }
  &.small {
    ::v-deep {
      .el-upload {
        width: 100px;
        height: 100px;
      }
      .el-upload-list__item {
        width: 100px;
        height: 100px;
      }
    }
  }
}
.uploader-type8 {
  ::v-deep {
    .el-upload {
      width: 100%;
    }
  }
  .uploader {
    border-radius: 5px;
    width: 100px;
    height: 100px;
    position: relative;
    border: 1px dashed #eee;
    .text {
      width: 100px;
      height: 100px;
      color: #999;
      @include flex-xyc2;
      i {
        font-size: 24px;
        margin-top: 10px;
      }
    }
    .f-span-required,
    .label {
      padding-top: 10px;
      line-height: 24px;
      text-align: center;
    }
    .tips {
      font-size: 12px;
      color: $color9;
      letter-spacing: 1px;
    }
    .mask {
      position: absolute;
      left: 10px;
      top: 10px;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
    }
    .loading {
      position: absolute;
      pointer-events: none;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0;
      z-index: 3;
    }
  }
  .file {
    padding: 10px;
    background: #eee;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: text;
    .type-icon {
      width: 20px;
      min-width: 20px;
      height: 30px;
      img {
        width: 20px;
      }
    }
    .text {
      flex: 1;
      height: 40px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      .name {
        display: inline-block;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .size {
        color: #aaa;
        padding-left: 10px;
      }
    }
    .actions {
      .el-icon-loading {
        font-size: 20px;
        color: #999;
        margin-right: 30px;
      }
      span {
        padding: 0 5px;
        cursor: pointer;
      }
    }
  }
}
.picture-uploader-type2 {
  width: 175px;
  padding: 10px;
  position: relative;
  margin-right: 20px;
  border: 1px solid #f2f2f2;
  &.mini {
    width: 100px;
    border: 1px dashed #eee;
    border-radius: 5px;
    .uploader {
      height: 80px;
      .text,
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
  .uploader {
    height: 130px;
    .text,
    img {
      width: 155px;
      height: 130px;
      color: $blue;
      background: #f2f2f2;
      flex-direction: column;
      i {
        font-size: 24px;
        margin-top: 10px;
      }
    }
    .mask {
      position: absolute;
      left: 10px;
      top: 10px;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
    }
  }
  .f-span-required,
  .label {
    padding-top: 10px;
    line-height: 24px;
    text-align: center;
  }
  .tips {
    font-size: 12px;
    color: $color9;
    letter-spacing: 1px;
  }
  .loading {
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0;
    z-index: 3;
  }
}
</style>
